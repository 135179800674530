import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function get(projectId) {
  const response = await graphqlClient.query({
    query: gql`
    query project {
      project(where: {id: {_eq: "${projectId}"}}) {
        id
        name
        description
        website
        apartments_available
        levels
        stage
        type
        classification
        address
        city_id
        location
        min_downpayment_percent
        max_downpayment_installments
        custom_extras
        discount_rate
        discount_extra_fee
        discount_enable
        financial_discount_enable
        quote_template
        templates
        max_days_quote
        max_days_optioned
        payment_days_reservation
        country_currency_id
        date_release
        date_ending
        business_name
        business_address
        allow_optioned
        city {
          id
          name
          country {
            id
            name
          }
        }
        city_zone {
          id
          name
        }
        suburb {
          id
          name
        }
        properties_total: properties_aggregate(
          where: {
            _and: {deleted: {_neq: true},
            property_type_id: {_eq: APARTMENT}
            }
          }
        )
        {
          aggregate {
            count
          }
        }
        properties_available: properties_aggregate(
          where: {
            _and: {deleted: {_neq: true},
              status: {_eq: AVAILABLE},
              property_type_id: {_eq: APARTMENT}
            }
          }
        )
        {
          aggregate {
            count
          }
        }
        project_setting_values {
          id
          value
          project_setting
        }
        project_images {
          id
          img_url
          type
          project_image_gallery
        }
        property_developer {
          id
          name
          property_developer_settings {
            id
            value
            property_developer_setting_value_id
          }
        }
        project_unit_types {
          id
          type
          img_url
        }
        project_stage {
          id
          name
        }
        country_currency {
          country_id
          currency {
            id
            name
            code
            symbol
          }
        }
      }
    }
    `,
  });

  return response.data.project[0];
}

async function getAvailableProjectStages() {
  const response = await graphqlClient.query({
    query: gql`
      query stages {
        project_stage {
          id
          name
        }
      }
    `,
  });

  return response.data.project_stage;
}

async function listProjects(developerId) {
  const response = await graphqlClient.query({
    query: gql`
      {
        project(
          where: {
						_and: [
							{
								_or: [
									{deleted: {_neq: true}},
									{deleted: {_is_null: true}}
								]
							},
							{property_developer_id: {_eq: "${developerId}"}}
						]
					},
          order_by: {
            name: asc
          }
        ) {
          id
          name
          description
          location
          apartments_available
          levels
          stage
          type
          project_images {
            id
            img_url
            type
            project_image_gallery
          }
          project_stage {
            id
            name
          }
          country_currency {
            currency {
              id
              name
              code
              symbol
            }
          }
        }
      }
`,
  });

  return response.data.project;
}

async function addProject(project) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation newProject($prj: project_insert_input!) {
        insert_project_one(object: $prj) {
          id
          name
          property_developer {
            id
            name
          }
        }
      }
    `,
    variables: {
      prj: {
        name: project.name,
        description: project.description,
        website: project.website,
        apartments_available: project.propertiesCount,
        levels: project.levelsCount,
        stage: project.stage,
        type: project.type,
        classification: project.classification,
        address: project.address,
        city_id: project.city,
        city_zone_id: project.zone,
        suburb_id: project.neighborhood,
        location: project.location,
        property_developer_id: project.developerId,
        payment_days_reservation: project.paymentDaysReservation,
        country_currency_id: project.countryCurrencyId,
        max_days_optioned: project.maxDaysOptioned,
        max_days_quote: project.maxDaysQuote,
        date_release: project.dateRelease,
        date_ending: project.dateEnding,
        business_name: project.businessName,
        business_address: project.businessAddress,
      },
    },
  });

  return response.data.insert_project_one;
}

async function updProject(id, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updPrj($fields: project_set_input) {
        update_project(
          _set: $fields,
          where: {id: {_eq: "${id}"}}
        ) {
          affected_rows
        }
      }
    `,
    variables: {
      fields: fields,
    },
  });

  return response.data;
}

async function getTypes() {
  const response = await graphqlClient.query({
    query: gql`
      {
        project_type {
          id
          name
        }
      }
    `,
  });

  return response.data.project_type;
}

async function getProjectImageGallery() {
  const response = await graphqlClient.query({
    query: gql`
      {
        project_image_gallery {
          id
          name
        }
      }
    `,
  });

  return response.data.project_image_gallery;
}

async function addProjectImage(projectImage) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation newProjectImage($projectImage: project_image_insert_input!) {
        insert_project_image_one(object: $projectImage) {
          id
          title
          alternative_text
          type
          location
          project_id
          img_url
        }
      }
    `,
    variables: {
      projectImage: {
        title: projectImage.title,
        alternative_text: projectImage.alternative_text
          ? projectImage.alternative_text
          : null,
        img_url: projectImage.img_url,
        type: projectImage.type ? projectImage.type : null,
        location: projectImage.location ? projectImage.location : null,
        project_image_gallery: projectImage.project_image_gallery,
        project_id: projectImage.project_id,
      },
    },
  });

  return response.data.insert_project_one;
}

async function deleteProjectImage(projectImageId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation  {
        delete_project_image(where: {
          id: {
            _eq: "${projectImageId}"
          }
        }) {
          affected_rows
        }
      }
    `,
  });
  return response.data.delete_project_image;
}

async function getProjectImages(projectId, projectImageGallery = null) {
  const response = await graphqlClient.query({
    query: gql`
    {
      project_image (where:{
        project_id: {
          _eq: "${projectId}"
        },
        project_image_gallery:{
          _eq: ${projectImageGallery}
        }
      }){
        id
        title
        location
        alternative_text
        img_url
        type
        project_image_gallery
        project_id
        projectImageGalleryByProjectImageGallery {
          id
          name
        }
      }
    }
    `,
  });

  return response.data.project_image;
}

async function updProjectImage(projectId, fields, projectImageGallery = null) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updProjectImage($fields: project_image_set_input!, $gallery: project_image_gallery_enum) {
        update_project_image(where:{
          project_id: {
            _eq: "${projectId}"
          },
          project_image_gallery: {
            _eq: $gallery
          }
        }, _set: $fields) {
          returning {
            id
            title
            location
            img_url
            project_id
            project_image_gallery
          }
        }
      }
    `,
    variables: {
      fields: fields,
      gallery: projectImageGallery,
    },
  });

  return response.data.insert_project_one;
}

async function addProjectSetting(projectId, settingValue, projectSetting) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addProjectSetting($setting: project_setting_insert_input!) {
        insert_project_setting_one(object: $setting) {
          id
          value
          project_setting
        }
      }
    `,
    variables: {
      setting: {
        project_id: projectId,
        value: settingValue,
        project_setting: projectSetting,
      },
    },
  });
  return response.data.insert_project_setting_one;
}

async function updProjectSettingById(projectSettingId, setting) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updProjectSetting($setting: project_setting_set_input!) {
        update_project_setting(where: {
          id: {
            _eq: "${projectSettingId}"
          }
        }, _set: $setting) {
          affected_rows
          returning {
            id
            value
          }
        }
      }
    `,
    variables: {
      setting: setting,
    },
  });

  return response.data.update_project_setting;
}

async function addProjectTaxes(taxes) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addProjectTax($taxes: [project_tax_insert_input!]!) {
        insert_project_tax(objects: $taxes) {
          affected_rows
        }
      }
    `,
    variables: {
      taxes: taxes,
    },
  });
  return response.data.insert_project_tax;
}

async function delProjectTax(projectTaxId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation delProjectTax($projectTaxId: uuid) {
        delete_project_tax(where: { id: { _eq: $projectTaxId } }) {
          affected_rows
        }
      }
    `,
    variables: {
      projectTaxId: projectTaxId,
    },
  });
  return response.data.insert_project_tax;
}

async function updProjectTax(projectTaxId, taxes) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updProjectTax(
        $projectTaxId: uuid!
        $object: project_tax_set_input
      ) {
        update_project_tax_by_pk(
          pk_columns: { id: $projectTaxId }
          _set: $object
        ) {
          id
          proportion
          tax {
            id
            proportion
            rate
          }
        }
      }
    `,
    variables: {
      projectTaxId: projectTaxId,
      object: taxes,
    },
  });
  return response.data.update_project_tax_by_pk;
}

async function addDownpaymentType(projectDownpayment) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addDownpayment($input: project_downpayment_type_insert_input!) {
        insert_project_downpayment_type_one(object: $input) {
          id
          max_payment_installments
          downpayment_rate
          pcv_rate
          reservation
          is_reservation_percent
        }
      }
    `,
    variables: {
      input: projectDownpayment,
    },
  });

  return response.data.insert_project_downpayment_type_one;
}

async function getDownpaymentTypes(projectId) {
  const response = await graphqlClient.query({
    query: gql`
      query getDownpaymentTypes($projectId: uuid!) {
        project_downpayment_type(
          where: { project_id: { _eq: $projectId } }
          order_by: { active: desc }
        ) {
          id
          project {
            id
            name
          }
          name
          max_payment_installments
          pcv_rate
          pcv_payment_day
          downpayment_rate
          reservation
          is_reservation_percent
          active
        }
      }
    `,
    variables: {
      projectId,
    },
  });
  return response.data.project_downpayment_type.length
    ? response.data.project_downpayment_type
    : [];
}

async function deleteDownpaymentType(downPaymentTypeId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation deleteProjectDownpaymentType($id: uuid!) {
        delete_project_downpayment_type_by_pk(id: $id) {
          id
        }
      }
    `,
    variables: {
      id: downPaymentTypeId,
    },
  });

  return response.data.delete_project_downpayment_type_by_pk || null;
}

async function updDownpaymentType(downPaymentTypeId, downpaymentInput) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updateProjectDownpaymentType(
        $id: uuid!
        $set: project_downpayment_type_set_input!
      ) {
        update_project_downpayment_type_by_pk(
          pk_columns: { id: $id }
          _set: $set
        ) {
          id
        }
      }
    `,
    variables: {
      id: downPaymentTypeId,
      set: downpaymentInput,
    },
  });

  return response.data.update_project_downpayment_type_by_pk || null;
}

async function deleteById(projectId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation deleteProject($projectId: uuid!) {
        deleteProject(project: { id: $projectId }) {
          id
          deleted
        }
      }
    `,
    variables: {
      projectId,
    },
  });
  return response.data.deleteProject;
}

async function getSummary(projectId) {
  const response = await graphqlClient.query({
    query: gql`
    query project {
      project(where: {id: {_eq: "${projectId}"}}) {
        id
        name
        stage
        properties_total: properties_aggregate(where: {_and: {
          _or:[
            {deleted: {_eq: false}}, {deleted: {_is_null: true}}
          ],
          property_type_id: {
            _nin: [
              PARKING,
              WAREHOUSE
            ]
          }}
        }) {
          aggregate {
            count
          }
        }
        properties_available: properties_aggregate(where: {_and: {
          _or:[
            {deleted: {_eq: false}}, {deleted: {_is_null: true}}
          ],
          status: {_eq: AVAILABLE},
          property_type_id: {
            _nin: [
              PARKING,
              WAREHOUSE
            ]
          }}
        }) {
          aggregate {
            count
          }
        }
        project_setting_values {
          id
          value
          project_setting
        }
      }
    }
    `,
  });

  return response.data.project[0];
}

async function getImages(projectId) {
  const response = await graphqlClient.query({
    query: gql`
    query project {
      project(where: {id: {_eq: "${projectId}"}}) {
        id
        name
        project_images {
          id
          img_url
          type
          project_image_gallery
        }
      }
    }
    `,
  });

  return response.data.project[0];
}

async function getExpoValues(developerId) {
  const response = await graphqlClient.query({
    query: gql`
      {
  property_developer_by_pk(id:"${developerId}") {
    name
    project_expo: projects_aggregate(where:{
      project_setting_values: {
        project_setting: {
          _eq:EXPO_ID
        }
      }
    }) {
      aggregate {
        count
      }
    }
    project_featured: projects_aggregate(where:{
      project_setting_values: {
        _and:[
          {
            project_setting: {
            	_eq:FEATURED_DEVELOPER_PROJECT
          	}
          },
          {
            value: {
              _eq: "1"
            }
          }
        ]
      }
    }) {
      aggregate {
        count
      }
    }
  }
}
    `,
  });

  const { project_expo, project_featured } =
    response.data.property_developer_by_pk;

  return {
    projectExpoCount: project_expo.aggregate.count,
    projectFeatureCount: project_featured.aggregate.count,
  };
}

async function delSetting(settingId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation {
        delete_project_setting_by_pk(id: "${settingId}") {
          id
        }
      }
    `,
  });
  return response.data.delete_project_setting_by_pk;
}

export default {
  get,
  getTypes,
  getAvailableProjectStages,
  addProject,
  listProjects,
  updProject,
  getProjectImageGallery,
  addProjectImage,
  deleteProjectImage,
  getProjectImages,
  updProjectImage,
  addProjectSetting,
  updProjectSettingById,
  addProjectTaxes,
  delProjectTax,
  updProjectTax,
  addDownpaymentType,
  getDownpaymentTypes,
  deleteDownpaymentType,
  updDownpaymentType,
  deleteById,
  getSummary,
  getImages,
  getExpoValues,
  delSetting,
};